<script lang="ts" setup>
import {ErrorMessage} from "vee-validate";
import Error from "~/components/layouts/notice/Error.vue";
import {Vue3Lottie} from "vue3-lottie";
import {fetchData} from "~/utils/api";
import {endpoints} from "~/constants/endpoints";
import {useToast} from "~/components/ui/toast";
import {OTPPasswordRecovery, OTPSend} from "~/services/user-management-service";

const loading = ref(false)
const Loader = useIsLoadingStore()
const reSendOTPActive = ref(true)
const submitButton = ref(null)
const {t} = useI18n()
const {toast} = useToast()
const userStore = useUserStore()
const props = defineProps({
    type: {
        type: String,
        default: 'email'
    },
    email: {
        type: String,
    },
    phone: {
        type: String
    },
    newPassword: {
        type: String,
        required: true
    }
})
const emit = defineEmits(['done'])

const SendOTP = async () => {
    let otpObject = {
        email: props.email
    }
    if (props.type === 'phone_number') {
        otpObject = {
            phone: props.phone
        }
    }

    await OTPPasswordRecovery(otpObject)
    reSendOTPActive.value = false
    setTimeout(() => {
        reSendOTPActive.value = true
    }, 60000)
}

SendOTP()

interface OTPCredencials {
    email?: string
    phone?: string
    code: string
    newPassword: string
}

async function handleOTPSubmit(values, context) {
    const credentials: OTPCredencials = {
        code: values.pin.join(''),
        newPassword: props.newPassword
    }
    if (props.type === 'phone_number' && props.phone) {
        credentials.phone = props.phone
    }
    else if (props.type === 'email' && props.email) {
        credentials.email = props.email
    }
    if (!credentials.email && !credentials.phone) return

    loading.value = true

    await fetchData(endpoints.user.changePassword, {
        method: "POST",
        body: credentials
    }).then(({data, meta}) => {
        emit('done')
    }).catch((error) => {
        context.setErrors({global: error.data.errors[0]})
        console.error(error.data.errors[0])
    }).finally(() => {
        loading.value = false
        Loader.set(false)
    })
}

function handleComplete() {
    submitButton.value?.$el?.click()
}
</script>

<template>
    <UiDialogHeader>
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:mail-check-fill"/>
        <UiDialogTitle class="text-center">{{ $t("Enter Verification Code") }}</UiDialogTitle>
        <UiDialogDescription v-if="type === 'email'"
                             class="text-gray-500 border-b-gray-300 border-b pb-5 text-center">
            {{ $t("We’ve sent a code to") }} {{ email }} !
        </UiDialogDescription>
        <UiDialogDescription v-else-if="type === 'phone_number'"
                             class="text-gray-500 border-b-gray-300 border-b pb-5 text-center">
            {{ $t("We’ve sent a code to") }} {{ phone }}!
        </UiDialogDescription>
    </UiDialogHeader>

    <UiForm ref="OTPForm" class="grid gap-6 mt-6" @submit="handleOTPSubmit">
        <CustomUiInputPin @complete="handleComplete"/>
        <ErrorMessage
                v-slot="{ message }"
                as="p"
                class="text-sm font-medium text-red-500 top-auto left-auto"
                name="global"
        >
            <Error :message="message"/>
        </ErrorMessage>
        <UiButton ref="submitButton"
                  :disabled="loading"
                  class="ys-button w-full"
        >
            {{ $t("Submit Code") }}
        </UiButton>
        <div class="text-center">
            <div class="text-sm text-slate-50043 mb-1">{{ $t("Experiencing issues receiving the code?") }}</div>
            <UiButton v-if="reSendOTPActive" class="text-sm" variant="link" @click="SendOTP">{{
                    $t("Resend code")
                }}
            </UiButton>
            <client-only v-else>
                <Vue3Lottie
                        :height="30"
                        :width="30"
                        animationLink='/Loader/loader-p.json'
                />
            </client-only>
        </div>
    </UiForm>
</template>

<style lang="scss" scoped>

.ys-otp-question-text {
  font-size: 14px;
  color: rgba(82, 88, 102, 1);
}

</style>